import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const PaslanmazHasirTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Paslanmaz Hasır Tel - Erez Kaynak'
        description='Firmamız 304 kalite tel yada 316 kalite tel malzeme kullanarak müşterilerimizin paslanmaz hasır tel ihtiyaçlarını, istedikleri göz ve ebatlarda imalatını gerçekleştirmektedir. Kalınlık : 2mm den 8mm tel kalınlığına kadar çelik hasır imalatını yapabiliyoruz.'
        keywords='paslanmaz hasır tel,paslanmaz kafes tel,paslanmaz hasır tel kullanım alanları,paslanmaz çesan,paslanmaz fens,paslanmaz tel kafes,paslanmaz puntalı tel,paslanmaz kafes,paslanmaz hasır tel fiyatları,paslanmaz kafes tel fiyatları,paslanmaz çesan fiyatları,paslanmaz fens fiyatları,paslanmaz tel kafes fiyatları,paslanmaz puntalı tel fiyatları,paslanmaz kafes fiyatları,paslanmaz hasır tel imalatı,paslanmaz hasır tel istanbul,özel paslanmaz hasır tel'
        url='paslanmaz-hasir-tel'
        imgurl={getImageByName('ozelgozhasirtel').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('ozelgozhasirtel').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Paslanmaz Hasır Tel' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız 304 kalite tel yada 316 kalite tel malzeme kullanarak müşterilerimizin paslanmaz hasır tel ihtiyaçlarını,
                istedikleri göz ve ebatlarda imalatını gerçekleştirmektedir.
              </p>
              <p>
                <strong>304 kalite:</strong> Isı,su vb etkenlere karşı dirençlidir ve pas tutmaz.
              </p>
              <p>
                <strong>316 kalite:</strong> 304'e göre daha fazla ısıya ve kimyasal etkenlere (asit, deniz suyu vb) karşı dirençlidir.
              </p>
              <p>
                <strong>Kalınlık:</strong> 2mm'den 8mm tel kalınlığına kadar çelik hasır imalatını yapabiliyoruz.
              </p>
              <p>
                <strong>Göz aralığı:</strong> Standart olarak merkezden merkeze 15mm , 20mm , 25mm ve katlarında yapabiliyoruz. Örnek
                olarak; 15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz ölçüde çelik hasır yapabilmekteyiz. Özel göz
                aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan çelik hasırı uygun
                bir maaliyet ile üretiyoruz.
              </p>
              <p>
                <strong>Ebat:</strong> Standart kalıplarımız da en büyük ölçü 2200x3000mm dir. Onunda dışında istenilen boy ve ebatta çelik
                hasır imalatı yapılmaktadır.Örnek olarak 50cmx175cm, 155cmx275cm gibi...
              </p>
            </ContentText>
            <Grid container justify='center' spacing={3}>
              <Grid item>
                <InnerLink title='304 Kalite Paslanmaz Hasır Tel' url='/304-kalite-paslanmaz-hasir-tel' />
              </Grid>
              <Grid item>
                <InnerLink title='316 Kalite Paslanmaz Hasır Tel' url='/316-kalite-paslanmaz-hasir-tel' />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('ozelgozhasirtel')} alt='özel göz hasır tel imalatı erez kaynak' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}
// TODO: tablolara başlık gerekiyor
export default PaslanmazHasirTelPage
